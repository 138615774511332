/**
 * Utility to manage client tags/reservation tags
 */
var modules = modules || {}
modules.tagsmanager = (function () {
  var helpers = {
    fontStyle: {
      color: '#999',
      fontSize: 12,
      margin: '10px 0 3px',
      fontFamily: 'Roboto',
    },
    labelCss: {
      color: '#999',
      fontSize: 14,
      fontWeight: 'bold',
      paddingBottom: 5,
      margin: '10px 0 3px',
      fontFamily: 'Roboto',
    },
    formInputCss: {
      color: '#555',
    },
    inputCss: {
      borderRadius: 3,
      margin: 3,
      borderColor: '#DDD',
      color: '#777',
    },
    inputBoxCss: {
      height: 36,
      width: 250,
      padding: 0,
      margin: 0,
      color: '#999',
      paddingLeft: 10,
      border: '1px solid #DDD',
      borderRadius: 3,
    },
    inputPCss: {
      color: '#555',
    },
    instructionCss: {
      paddingBottom: 10,
      fontSize: 11,
      color: '#AAA',
      fontStyle: 'italic',
    },
    newElementCss: {
      paddingTop: 5,
    },
    buttonImgSize: {
      width: '31px',
      height: '36px',
    },
    buttonStyle: {
      margin: '0px 5px 0px 0px',
      cursor: 'pointer',
      float: 'left',
      display: 'block',
    },
    buttonHoverStyle: {
      float: 'left',
      position: 'absolute',
      display: 'block',
      pointerEvents: 'none',
    },
    baseOuterPopupStyle: {
      position: 'relative',
      display: 'block',
      padding: 5,
      margin: 'auto',
      textAlign: 'center',
      borderRadius: 3,
      backgroundColor: 'grey',
      color: 'white',
    },
    baseInnerPopupStyle: {
      position: 'absolute',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '5px 5px 0px 5px',
      borderColor: 'grey transparent transparent transparent',
    },
    loadingBackground: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    loadingSpinner: {
      height: '70px',
      margin: '20px auto 0',
    },

    saveTags: function (params) {
      if (helpers.save_in_progress) {
        return
      }
      helpers.save_in_progress = true
      $.ajax({
        url: '/api-yoa/tags/' + helpers.venue_name + '/save',
        method: 'POST',
        data: 'domain=' + helpers.domain + '&' + params,
        success: function () {
          helpers.refresh()
        },
        complete: function () {
          helpers.save_in_progress = false
        },
      })
    },

    editTagNameDisplay: function (id, tag_name, tag_name_display) {
      $.ajax({
        url: '/api-yoa/tags/' + helpers.venue_name + '/edit-name',
        method: 'POST',
        data: { tag_group_id: id, tag_name: tag_name, tag_name_display: tag_name_display },
        success: function () {
          helpers.refresh()
        },
      })
    },

    restoreTag: function (id, tag_name) {
      $.ajax({
        url: '/api-yoa/tags/' + helpers.venue_name + '/restore',
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ tag_group_id: id, tag_id: tag_name }),
        success: function () {
          helpers.refresh()
        },
      })
    },

    tagGroupNameField: React.createClass({
      render: function () {
        var is_disabled = this.props.is_disabled
        return (
          <div className="input-area input-col float-left">
            <p className="label" style={helpers.labelCss}>
              Category name
            </p>
            <p className="input">
              <input
                type="text"
                data-test="category-text"
                name={this.props.new_tag ? 'name' : 'name_display'}
                disabled={is_disabled}
                style={_.extend({}, helpers.inputBoxCss, { fontSize: '120%', backgroundColor: is_disabled ? '#EEE' : '#FFF' })}
                defaultValue={this.props.name_display || this.props.name}
              />
              {this.props.new_tag ? undefined : <input type="hidden" name="name" defaultValue={this.props.name} />}
            </p>
          </div>
        )
      },
    }),

    colorSelection: React.createClass({
      onClickHandler: function (e) {
        $('#colorpicker').show()
        e.stopPropagation()
      },

      render: function () {
        return (
          <div className="form-element input-col float-left">
            <p className="label" style={helpers.labelCss}>
              Color
            </p>
            <p className="input" style={helpers.formInputCss} />
            <Common.ColorSwatch
              name="color"
              onClickHandler={this.onClickHandler}
              colors={[
                '#df39b2',
                '#f35ac3',
                '#f374d0',
                '#f49edd',
                '#f0c8e5',
                '#e21d3e',
                '#ee415b',
                '#fa637a',
                '#fd8396',
                '#ff9aa1',
                '#f57929',
                '#fd9e4e',
                '#febc53',
                '#ffe058',
                '#fdf66e',
                '#43d240',
                '#7ce36a',
                '#95e772',
                '#b5ec83',
                '#d4f195',
                '#04c172',
                '#04da76',
                '#4be984',
                '#8ef3b0',
                '#affbbf',
                '#15c1b7',
                '#00c9ba',
                '#2cdcd0',
                '#59e9e0',
                '#8ff4f0',
                '#06a4ff',
                '#1dc4ff',
                '#61d4fb',
                '#90ddff',
                '#b3e8ff',
                '#1579ff',
                '#4390f7',
                '#67a6fd',
                '#7fb6ff',
                '#99c5ff',
                '#5f6eff',
                '#7d8fff',
                '#88a5f5',
                '#a4b9ff',
                '#bdc4fb',
                '#5c8aa1',
                '#7697a8',
                '#97aeb9',
                '#cad6da',
                '#e2e7e9',
              ]}
              inputStyle={_.extend({}, helpers.inputBoxCss, { width: 100 })}
              color={this.props.color ? this.props.color : '#D22D2D'}
            />
          </div>
        )
      },
    }),

    classificationSelection: React.createClass({
      render: function () {
        return this.props.tag_group_id ? (
          <input type="hidden" name="scope" value={Number(this.props.is_global)} />
        ) : (
          <div className="form-element">
            <p className="input" style={helpers.inputPCss}>
              <input
                type="radio"
                name="scope"
                data-test="global-location"
                value="1"
                defaultChecked={this.props.is_global}
                style={_.extend({}, helpers.inputCss, { marginRight: 5 })}
                disabled={this.props.disable_global_tag_editing}
              >
                {' '}
                Global (used by all locations)
              </input>
              <input
                type="radio"
                name="scope"
                data-test="local-loation"
                value="0"
                defaultChecked={!this.props.is_global}
                style={_.extend({}, helpers.inputCss, { marginRight: 5, marginLeft: 10 })}
              >
                {' '}
                Local (used by this locations)
              </input>
            </p>
            <p style={helpers.instructionCss}>
              {'Control whether this category is shared by all linked locations within this group, or only used by ' + helpers.venue_name}
            </p>
          </div>
        )
      },
    }),

    superuserSelection: React.createClass({
      getInitialState: function () {
        return {
          selected: this.props.privacy == 'PRIVATE',
        }
      },

      onChangeHandler: function (e) {
        this.setState({ selected: $(e.currentTarget).is(':checked') })
      },

      render: function () {
        return (
          <div>
            <input type="hidden" name="privacy" value={this.state.selected ? 'PRIVATE' : 'PUBLIC'} />
            {this.props.is_disabled ? undefined : (
              <span>
                <div className="form-element" style={{ clear: 'both', height: 25 }}>
                  <p className="input" style={helpers.formInputCss}>
                    <modules.formElements.create
                      type="checkbox"
                      name="super_user_selection"
                      data-test="superusers-checkbox"
                      checked={this.props.privacy == 'PRIVATE'}
                      onClickHandler={this.onChangeHandler}
                      inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                      labelStyle={_.extend({}, helpers.inputCss, { marginRight: 5 })}
                      postLabelStyle={helpers.inputPCss}
                      label="Visible to Superusers only (not recommended)"
                    />
                  </p>
                </div>
                <div style={helpers.instructionCss}>
                  If selected, tags within this category can only be viewed on profiles by users with Superuser permissions
                </div>
              </span>
            )}
          </div>
        )
      },
    }),

    restrictedSelection: React.createClass({
      getInitialState: function () {
        return {
          selected: this.props.is_restricted,
        }
      },

      onChangeHandler: function (e) {
        this.setState({ selected: $(e.currentTarget).is(':checked') })
      },

      render: function () {
        return (
          <div>
            <input type="hidden" name="is_restricted" value={Number(this.state.selected)} />
            {this.props.is_disabled ? undefined : (
              <span>
                <div className="form-element" style={{ clear: 'both', height: 25 }}>
                  <p className="input" style={helpers.formInputCss}>
                    <modules.formElements.create
                      type="checkbox"
                      name="restricted_selection"
                      onClickHandler={this.onChangeHandler}
                      checked={this.props.is_restricted}
                      inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                      labelStyle={_.extend({}, helpers.inputCss, { marginRight: 5 })}
                      postLabelStyle={helpers.inputPCss}
                      label="Restricted Tag Category"
                    />
                  </p>
                </div>
                <div style={helpers.instructionCss}>
                  If selected, tags within this category can only be applied to profiles by users with Restricted Tag Category user
                  permission
                </div>
              </span>
            )}
          </div>
        )
      },
    }),

    showchitSelection: React.createClass({
      getInitialState: function () {
        return {
          selected: this.props.show_chit,
        }
      },

      onChangeHandler: function (e) {
        this.setState({ selected: $(e.currentTarget).is(':checked') })
      },

      render: function () {
        return (
          <span>
            <div className="form-element" style={{ clear: 'both', height: 25 }}>
              <p className="input" style={helpers.formInputCss}>
                <modules.formElements.create
                  data-test="show-chit"
                  type="checkbox"
                  name="show_chit_selection"
                  onClickHandler={this.onChangeHandler}
                  checked={this.props.show_chit}
                  inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                  labelStyle={_.extend({}, helpers.inputCss, { marginRight: 5 })}
                  postLabelStyle={helpers.inputPCss}
                  label="Show on chit"
                />
              </p>
              <input type="hidden" name="show_chit" value={Number(this.state.selected)} />
            </div>
            <div style={helpers.instructionCss}>Control whether or not tags within this category are printed on the reservation chit</div>
          </span>
        )
      },
    }),

    followerTagSelection: React.createClass({
      getInitialState: function () {
        return {
          mode: 'view',
        }
      },

      onMouseOver: function () {
        this.setState({ mode: 'edit' })
      },

      onMouseOut: function () {
        this.setState({ mode: 'view' })
      },

      onRemoveHandler: function (e) {
        if (this.props.onRemoveHandler) {
          this.props.onRemoveHandler($(e.currentTarget).attr('data-id'))
        }
      },

      render: function () {
        return (
          <div
            onMouseOver={this.onMouseOver}
            key={this.props.key}
            onMouseOut={this.onMouseOut}
            style={{
              padding: '4px 5px',
              float: 'left',
              cursor: 'pointer',
              margin: '0px 10px 0px 0px',
              backgroundColor: '#DDD',
              borderRadius: 3,
            }}
          >
            <span key={1} style={{ float: 'left' }}>
              {this.props.name}
            </span>
            <span
              key={2}
              data-id={this.props.value}
              onClick={this.onRemoveHandler}
              style={{
                display: 'block',
                visibility: this.state.mode == 'edit' ? 'visible' : 'hidden',
                float: 'left',
                fontWeight: 'bold',
                padding: '0px 3px',
              }}
            >
              x
            </span>
            <input type="hidden" name="fid" value={this.props.value} />
          </div>
        )
      },
    }),

    followersSelection: React.createClass({
      getInitialState: function () {
        return {
          selectedOptions: _.values(this.props.followers) || [],
        }
      },

      onChangeHandler: function (e) {
        var selectedValue = $(e.currentTarget).val(),
          selectedName = $(e.currentTarget).find('option:selected').text(),
          selectedOptionIds = _.collect(this.state.selectedOptions, 'id')
        if (selectedValue != '' && !_.contains(selectedOptionIds, selectedValue)) {
          this.state.selectedOptions.push({ full_name: selectedName, id: selectedValue })
          this.setState({
            selectedOptions: this.state.selectedOptions,
          })
        }
      },

      onRemoveHandler: function (selectedValue) {
        this.setState({ selectedOptions: _.reject(this.state.selectedOptions, { id: selectedValue }) })
      },

      render: function () {
        var optionsLookup = _.object(
            helpers.booked_by_users.map(function (b, key) {
              return [b.id, b.name]
            })
          ),
          selectedOptionIds = _.collect(this.state.selectedOptions, 'id'),
          options = helpers.booked_by_users.map(function (b, key) {
            return _.contains(selectedOptionIds, b.id) ? undefined : (
              <option key={key} value={b.id}>
                {b.name}
              </option>
            )
          }, this),
          selectedOptionNames = _.map(
            this.state.selectedOptions,
            function (o, key) {
              return <helpers.followerTagSelection name={o['full_name']} key={key} onRemoveHandler={this.onRemoveHandler} value={o['id']} />
            },
            this
          ),
          selectOptionsElement =
            _.size(options) > 0 ? (
              <select
                style={{ float: 'left', height: 20, width: 20, opacity: 0, top: -20, cursor: 'pointer', position: 'relative' }}
                onChange={this.onChangeHandler}
                name="followers"
              >
                <option value="">Select</option>
                {options}
              </select>
            ) : undefined

        return (
          <div className="form-element" style={helpers.newElementCss}>
            <p style={{ color: '#555' }}>Add followers</p>
            <span key={0} className="input" style={helpers.formInputCss}>
              <span key={0}>
                <span
                  data-test="add-followers"
                  style={{
                    width: 20,
                    height: 20,
                    fontSize: 15,
                    borderRadius: 3,
                    textAlign: 'center',
                    border: '1px solid #CCCCCC',
                    margin: '2px 1px',
                    background: '#dedede',
                    display: 'block',
                  }}
                >
                  +{selectOptionsElement}
                </span>
              </span>
            </span>
            <span key={1} style={{ float: 'left', marginTop: 10 }}>
              {selectedOptionNames}
            </span>
          </div>
        )
      },
    }),

    showReservationSelection: React.createClass({
      getInitialState: function () {
        return {
          selected: this.props.show_reservation,
        }
      },

      onChangeHandler: function (e) {
        this.setState({ selected: $(e.currentTarget).is(':checked') })
      },

      render: function () {
        return (
          <span>
            <div className="form-element" style={{ clear: 'both', height: 25 }}>
              <p className="input" style={helpers.formInputCss}>
                <modules.formElements.create
                  data-test="show-reservation-summary"
                  type="checkbox"
                  name="show_reservation_selection"
                  checked={this.props.show_reservation}
                  onClickHandler={this.onChangeHandler}
                  inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                  labelStyle={_.extend({}, helpers.inputCss, { marginRight: 5 })}
                  postLabelStyle={helpers.inputPCss}
                  label="Show on reservation summary"
                />
              </p>
            </div>
            <input type="hidden" name="show_reservation" value={Number(this.state.selected)} />
            <div style={helpers.instructionCss}>
              Control whether or not tags within this category are seen on emailed and printed reservation summaries
            </div>
          </span>
        )
      },
    }),

    showCustomTagsSelection: React.createClass({
      getInitialState: function () {
        return {
          selected: this.props.show_custom_tags_on_widget,
        }
      },

      onChangeHandler: function (e) {
        this.setState({ selected: $(e.currentTarget).is(':checked') })
      },

      render: function () {
        return (
          <span>
            <div className="form-element" style={{ clear: 'both', height: 25 }}>
              <p className="input" style={helpers.formInputCss}>
                <modules.formElements.create
                  type="checkbox"
                  name="show_custom_tags_on_widget_selection"
                  data-test="show-custom-tags-checkbox"
                  checked={this.props.show_custom_tags_on_widget}
                  onClickHandler={this.onChangeHandler}
                  inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                  labelStyle={_.extend({}, helpers.inputCss, { marginRight: 5 })}
                  postLabelStyle={helpers.inputPCss}
                  label="Show custom tags to guests"
                />
              </p>
            </div>
            <input type="hidden" name="show_custom_tags_on_widget" value={Number(this.state.selected)} />
            <div style={helpers.instructionCss}>Controls whether custom tags added to this category show to guests</div>
          </span>
        )
      },
    }),

    vipSelection: React.createClass({
      getInitialState: function () {
        return {
          selected: this.props.vip,
        }
      },

      onChangeHandler: function (e) {
        this.setState({ selected: $(e.currentTarget).is(':checked') })
      },

      render: function () {
        return (
          <span>
            <div className="form-element" style={{ clear: 'both', height: 25 }}>
              <p className="input" style={helpers.formInputCss}>
                <modules.formElements.create
                  type="checkbox"
                  data-test="visible-to-superusers"
                  name="vip_selection"
                  onClickHandler={this.onChangeHandler}
                  checked={this.props.vip}
                  inputCss={{ width: 40, padding: 2, margin: 3, border: 0 }}
                  labelStyle={_.extend({}, helpers.inputCss, { marginRight: 5 })}
                  postLabelStyle={helpers.inputPCss}
                  label="VIP"
                />
              </p>
            </div>
            <input type="hidden" name="vip" value={Number(this.state.selected)} />
            <div style={helpers.instructionCss}>
              If selected, any client profile containing a tag within this category will be treated as a VIP profile
            </div>
          </span>
        )
      },
    }),

    viewTag: React.createClass({
      getInitialState: function () {
        return {
          mode: 'view',
          new_value: '',
          edit_popup: false,
          deletePopup: false,
          isLoading: false,
          selectedTagCategory: null,
        }
      },
      onMouseOverHandler: function (el) {
        if (!this.props.isPodTagCategory) {
          this.setState({ mode: 'hover' })
        }
      },

      onMouseOutHandler: function (el) {
        this.setState({ mode: 'view' })
      },

      onTagClickHandler: function (e) {
        if (this.props.is_editable) {
          this.setState({ mode: 'edit' })
        }
        e.stopPropagation()
      },

      onKeyPressHandler: function (e) {
        if (e.charCode == 13) {
          if ($(this.getDOMNode()).find('input').val().trim() != $(this.getDOMNode()).find('input').attr('data-prev')) {
            this.setState({ edit_popup: true, new_value: $(this.getDOMNode()).find('input').val() })
          } else {
            this.setState({ mode: 'view' })
          }
        }
        e.stopPropagation()
      },

      onBlurHandler: function (e) {
        if ($(this.getDOMNode()).find('input').val().trim() != $(this.getDOMNode()).find('input').attr('data-prev')) {
          this.setState({ edit_popup: true })
        } else {
          this.setState({ mode: 'view' })
        }
        e.stopPropagation()
      },

      onRemoveHandler: function (el) {
        this.setState({ deletePopup: false })
        if (this.props.onRemoveHandler) {
          this.props.onRemoveHandler(el)
        }
        el.stopPropagation()
      },

      onDeleteCancel: function (el) {
        this.setState({ deletePopup: false })
        el.stopPropagation()
      },

      onSave: function () {
        var currentValue = $(this.getDOMNode()).val()
        if (currentValue.trim()) {
          helpers.saveTags($(this.getDOMNode()).closest('.tag-group-category').find('input').serialize())
        }
      },

      restoreDeletedTag: function () {
        helpers.restoreTag(this.props.tag_group_id, this.props.name)
      },

      onEditSave: function () {
        var tagNameDisplay = $(this.getDOMNode()).find('input').val().trim(),
          tagName = this.props.name,
          tagGroupId = this.props.tag_group_id
        helpers.editTagNameDisplay(tagGroupId, tagName, tagNameDisplay)
        this.setState({ edit_popup: false })
      },

      onEditCancel: function () {
        this.setState({ mode: 'view', edit_popup: false })
      },

      showsDeletePopup: function () {
        if (this.props.is_deletable) {
          const self = this
          this.setState({ deletePopup: true, isLoading: true, selectedTagCategory: null })
          $.ajax({
            type: 'GET',
            url: `/api-yoa/tags/client/${this.props.tag_group_id}`,
            success: function (response) {
              self.setState({ isLoading: false, selectedTagCategory: response.data })
            },
          })
        }
      },

      componentDidUpdate: function () {
        if (this.state.mode === 'edit') {
          $(this.getDOMNode()).find('input').focus()
          var currentValue = $(this.getDOMNode()).find('input').val()
          $(this.getDOMNode()).find('input').val('').val(currentValue)
        }
      },

      render: function () {
        var category_display = helpers.domain == 'VenueGroupClient' ? 'clients' : 'reservations'
        return this.state.mode === 'edit' ? (
          <span>
            <input
              className="input"
              name="tags[]"
              data-prev={this.props.name_display}
              defaultValue={this.props.name_display}
              onKeyPress={this.onKeyPressHandler}
              onBlur={this.onBlurHandler}
              style={{ margin: 3, padding: 3, height: 12, borderRadius: 3, minWidth: 30, verticalAlign: 'top' }}
            ></input>
            {this.state.edit_popup ? (
              <div className="delete-category local-popup">
                <span className="question">
                  {'Are you sure you want to change this tag?   '}
                  {' This will update all ' + category_display + ' with ' + this.props.name_display + ' to ' + this.state.new_value + ' .'}
                </span>
                <div className="delete-category-confirm-area">
                  <div className="link float-right">
                    <p className="button">
                      <a className="save-tag-group-btn" href="javascript:void(0);" onClick={this.onEditSave}>
                        Confirm
                      </a>
                    </p>
                    <p className="button plain">
                      <a href="javascript:void(0);" onClick={this.onEditCancel}>
                        nevermind
                      </a>
                    </p>
                    <div className="float-end"></div>
                  </div>
                </div>
                <div className="float-end"></div>
              </div>
            ) : undefined}
          </span>
        ) : (
          <div className="tag-wrapper">
            <div
              data-name={this.props.name}
              className="tag"
              onMouseOver={this.onMouseOverHandler}
              onMouseOut={this.onMouseOutHandler}
              style={{
                fontWeight: this.state.mode == 'view' ? 'normal' : 'bold',
                fontSize: 10,
                cursor: this.props.is_editable ? 'pointer' : 'default',
                color: sr.formatter.getContrastYIQ(this.props.color),
                backgroundColor: this.props.color,
                border: 0,
              }}
            >
              <span
                onClick={this.onTagClickHandler}
                style={{ padding: '2px 4px 3px 4px', float: 'left', fontWeight: 'bold', fontSize: 12 }}
              >
                {this.props.name_display}
              </span>
              <input type="hidden" name="tags[]" value={this.props.name} className={this.props.is_restorable ? 'restorable' : ''} />
              {this.props.is_deletable && !this.props.is_autotag ? (
                <span
                  data-tag-group-id=""
                  onMouseOver={this.onMouseOverHandler}
                  onMouseOut={this.onMouseOutHandler}
                  onClick={this.showsDeletePopup}
                  style={{
                    display: 'block',
                    visibility: this.state.mode == 'hover' ? 'visible' : 'hidden',
                    float: 'left',
                    padding: '2px',
                    height: '100%',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                >
                  x
                </span>
              ) : undefined}
              {this.props.is_restorable ? (
                <span
                  data-tag-group-id=""
                  onMouseOver={this.onMouseOverHandler}
                  onMouseOut={this.onMouseOutHandler}
                  onClick={this.restoreDeletedTag}
                  style={{
                    display: 'block',
                    visibility: this.state.mode == 'hover' ? 'visible' : 'hidden',
                    float: 'left',
                    padding: '2px',
                    height: '100%',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                >
                  x
                </span>
              ) : undefined}
            </div>
            {this.state.deletePopup && (
              <div className="delete-category local-popup">
                <span className="question">Delete this tag?</span>
                <p>Please Note: If deleted, any email or text campaigns scheduled to send to this tag will revert to drafts.</p>
                {this.state.selectedTagCategory?.tags_in_access_rule.includes(this.props.name) ? (
                  <p>
                    Please Note: This Client Tag is tied to one or more Access Rules. Deleting this Client Tag will remove the Client Tag
                    from the associated Access Rules.
                  </p>
                ) : undefined}
                <div className="delete-category-confirm-area">
                  {this.state.isLoading ? (
                    <div style={helpers.loadingBackground}>
                      <img
                        alt="sevenrooms-spinner"
                        style={helpers.loadingSpinner}
                        src={`${helpers.media_url}images/SevenRoomsLoader.svg`}
                      />
                    </div>
                  ) : undefined}
                  <div className="link float-right">
                    {!this.state.isLoading ? (
                      <p className="button danger">
                        <a
                          className=""
                          href="javascript:void(0);"
                          data-test="delete-tag-btn"
                          data-tag={this.props.name}
                          onClick={this.onRemoveHandler}
                        >
                          Delete
                        </a>
                      </p>
                    ) : undefined}
                    <p className="button plain">
                      <a href="javascript:void(0);" onClick={this.onDeleteCancel}>
                        Cancel
                      </a>
                    </p>
                    <div className="float-end"></div>
                  </div>
                </div>
                <div className="float-end"></div>
              </div>
            )}
          </div>
        )
      },
    }),

    dropdownOption: React.createClass({
      getInitialState: function () {
        return {
          active: false,
        }
      },

      onMouseOverHandler: function () {
        this.setState({ active: true })
      },

      onMouseOutHandler: function () {
        this.setState({ active: false })
      },

      render: function () {
        return (
          <div
            onMouseOver={this.onMouseOverHandler}
            onMouseOut={this.onMouseOutHandler}
            onClick={_.partial(this.props.onTagSelection, this.props.tag)}
            style={{
              padding: '5px 10px',
              fontFamily: 'Roboto',
              cursor: 'pointer',
              fontSize: 14,
              color: '#777',
              backgroundColor: this.state.active ? '#D7E6FF' : 'white',
            }}
          >
            {this.props.tag}
          </div>
        )
      },
    }),

    tagsDropdown: React.createClass({
      onClickHandler: function (e) {
        if (this.getDOMNode().contains(e.target)) {
          this.props.toggleDropdown(true)
        } else {
          this.props.toggleDropdown(false)
        }
      },

      componentWillMount: function () {
        document.addEventListener('click', this.onClickHandler, false)
      },

      componentWillUnmount: function () {
        document.removeEventListener('click', this.onClickHandler, false)
      },

      render: function () {
        var dropdown_options = _.difference(_.difference(this.props.allowed_list, this.props.existing_list), this.props.selected_list),
          filtered_tags = _.filter(
            dropdown_options,
            function (tag) {
              return (
                tag.toLowerCase().includes(this.props.prefix.toLowerCase()) &&
                !_.contains(this.props.selected_list, tag) &&
                !_.contains(this.props.existing_list, tag)
              )
            },
            this
          ),
          tags_selection = filtered_tags.map(function (tag) {
            return <helpers.dropdownOption tag={tag} key={tag} onTagSelection={this.props.onTagSelection} />
          }, this)
        return (
          <div style={{ maxHeight: 100, float: 'left', position: 'absolute', border: '1px solid #DDD', overflowY: 'scroll', width: 310 }}>
            {tags_selection}
          </div>
        )
      },
    }),

    newTag: React.createClass({
      getInitialState: function () {
        return {
          show_dropdown: false,
          prefix: '',
          list: [],
        }
      },

      addInputValue: function (target) {
        var currentList = this.state.list,
          selectedValue = $(target).val(),
          allowedList = this.props.allowed_list
        selectedValue = selectedValue.trim()
        if (!this.props.is_editable && !_.contains(allowedList, selectedValue)) {
          return
        }
        if (selectedValue && _.indexOf(currentList, selectedValue) == -1) {
          currentList.push(selectedValue)
          this.setState({ list: currentList, prefix: '' })
        }
        $(target).val('')
      },

      onKeyPressHandler: function (e) {
        if (e.charCode == 13) {
          this.addInputValue($(e.currentTarget))
        }
      },

      onClickHandler: function () {
        this.setState({ show_dropdown: true })
      },

      toggleDropdown: function (val) {
        this.setState({ show_dropdown: val })
      },

      onChangeHandler: function (e) {
        var v = $(e.currentTarget).val()
        this.setState({ show_dropdown: true, prefix: v })
      },

      onRemoveHandler: function (e) {
        var selectedTag = $(e.currentTarget).attr('data-tag'),
          filteredList = _.filter(
            this.state.list,
            function (item) {
              return item != selectedTag
            },
            this
          )
        this.setState({ list: filteredList })
      },

      getDuplicates: function ({ originalTagCategory, tagCategory, tagCategories }) {
        const tags = this._owner._owner.getActualTags({ originalTagCategory, tags: tagCategory['tags[]'] })
        return this._owner._owner
          .getActualCategories({ tagCategory, tagCategories })
          .flatMap(category => {
            return category.tags.map(t => category.tag_name_displays[t] || t)
          })
          .some(tag => tags.includes((tagCategory.tag_name_displays || {})[tag.trim()] || tag.trim()))
      },

      onSaveHandler: function (e) {
        if (
          this.getDuplicates({
            originalTagCategory: this.props.tag_group,
            tagCategory: $(this.getDOMNode()).closest('.tag-group-category').find('input').serializeObject(),
            tagCategories: this.props.tag_groups,
          })
        ) {
          this.props.setTagDuplicateWarningModalData({
            tagCategory: this.props.tag_group,
            tags: $(this.getDOMNode()).closest('.tag-group-category').find('input').serializeObject()['tags[]'],
          })
          this.props.setTagDuplicateWarningModalIsActive(true)
        } else {
          helpers.saveTags($(this.getDOMNode()).closest('.tag-group-category').find('input:not(.restorable)').serialize())
        }
      },

      onTagSelection: function (v) {
        var newList = this.state.list
        if (!_.contains(newList, v)) {
          this.setState({ list: newList.concat(v) })
          $(this.getDOMNode()).find('.tag-name-input').val('')
        }
      },

      render: function () {
        var selectedTags = this.state.list.map(function (tag, index) {
          return (
            <helpers.viewTag
              name={tag}
              name_display={this.props.tag_group.tag_name_displays[tag] || tag}
              onRemoveHandler={this.onRemoveHandler}
              key={index}
              is_new={true}
              color={this.props.tag_group.color_hex}
            />
          )
        }, this)
        return (
          <div style={{ overflow: 'hidden', padding: '0px 20px 10px 0px', margin: 3 }}>
            <div className="input-area input-col float-left">
              <p className="label" style={helpers.labelCss}>
                New tag(s)
              </p>
              <p className="input" style={{ overflow: 'hidden', float: 'left' }}>
                <input
                  type="text"
                  onChange={this.onChangeHandler}
                  onKeyPress={this.onKeyPressHandler}
                  onClick={this.onClickHandler}
                  name={this.props.is_editable ? 'tags[]' : undefined}
                  data-test="type-new-tag"
                  style={_.extend({}, helpers.inputBoxCss, { fontSize: '120%', fontWeight: '200', width: 300 })}
                  className="tag-name-input"
                  placeholder="Type new tag, press enter"
                />
                {this.state.show_dropdown ? (
                  <helpers.tagsDropdown
                    onTagSelection={this.onTagSelection}
                    toggleDropdown={this.toggleDropdown}
                    prefix={this.state.prefix}
                    selected_list={this.state.list}
                    existing_list={this.props.existing_list}
                    allowed_list={this.props.allowed_list}
                  />
                ) : undefined}
                {this.props.is_editable ? undefined : (
                  <span style={{ clear: 'both', display: 'block', padding: 5 }}>
                    <img width="13px" height="20px" style={{ float: 'left' }} src={helpers.media_url + 'images/icons/lock-icon.png'} />
                    <span style={{ color: '#AAA', fontSize: 12, paddingTop: 2, float: 'left', lineHeight: '20px', fontStyle: 'italic' }}>
                      choose tag from our pre-defined list for this category
                    </span>
                  </span>
                )}
                <div style={{ clear: 'both', padding: '15px 0px', margin: 0 }}>
                  <p className="button" style={{ float: 'left' }}>
                    <a onClick={this.onSaveHandler} data-test="save-new-tag-btn" href="javascript:void(0)" className="save-tag-group-btn">
                      Save
                    </a>
                  </p>
                  <a
                    style={{ float: 'left', lineHeight: '40px', paddingLeft: 20 }}
                    href="javascript:void(0)"
                    onClick={this.props.onClickNevermind}
                    data-test="cancel-new-tag-btn"
                    className="cancel-tag-group-btn"
                  >
                    Nevermind
                  </a>
                </div>
              </p>
            </div>
            <div style={{ padding: '40px 10px 10px 10px' }}>{selectedTags}</div>
          </div>
        )
      },
    }),

    viewTagCategory: React.createClass({
      getInitialState: function () {
        return {
          mode: 'view',
          active: false,
          showDeletePopup: false,
          isLoading: false,
          disableTooltip: false,
          edit_popup: false,
          deletePopup: false,
          disablePopup: false,
          priority_up_popup: false,
          priority_down_popup: false,
          selectedTagCategory: null,
        }
      },

      onMouseOverHandler: function () {
        if (this.state.mode != 'add') {
          this.setState({ active: true })
        }
      },

      onMouseOutHandler: function () {
        if (this.state.mode != 'add') {
          this.setState({ active: false })
        }
      },

      addNewTagHandler: function () {
        this.setState({ mode: 'add' })
        window.scrollBy(0, 100)
      },

      componentDidUpdate: function () {
        if (this.refs.new_tag) {
          this.refs.new_tag.setState({ list: [] })
        }
      },

      onEditClick: function () {
        this.setState({ mode: 'edit' })
      },

      onClickNevermind: function () {
        this.setState({ mode: 'view' })
      },

      showsDisablePopup: function (e) {
        const self = this
        this.setState({ showDisablePopup: true, isLoading: true, selectedTagCategory: null })
        $.ajax({
          type: 'GET',
          url: `/api-yoa/tags/client/${this.props.tag_group.id}`,
          success: function (response) {
            self.setState({ isLoading: false, selectedTagCategory: response.data })
          },
        })
        e.stopPropagation()
      },

      showDeletePopup: function (e) {
        const self = this
        this.setState({ showDeletePopup: true, isLoading: true, selectedTagCategory: null })
        $.ajax({
          type: 'GET',
          url: `/api-yoa/tags/client/${this.props.tag_group.id}`,
          success: function (response) {
            self.setState({ isLoading: false, selectedTagCategory: response.data })
          },
        })
        e.stopPropagation()
      },

      hideDeletePopup: function (e) {
        this.setState({ showDeletePopup: false })
        e.stopPropagation()
      },

      hideDisabledPopup: function (e) {
        this.setState({ showDisablePopup: false })
        e.stopPropagation()
      },

      deleteCategory: function (e) {
        helpers.saveTags($(e.currentTarget).closest('.tag-group-category').find('input').serialize() + '&delete=1')
        this.setState({ showDeletePopup: false })
        e.stopPropagation()
      },

      disableCategory: function (value, e) {
        helpers.saveTags($(e.currentTarget).closest('.tag-group-category').find('input').serialize() + '&disabled=' + Number(!value))
        this.setState({ showDisablePopup: false })
        e.stopPropagation()
      },

      changeSortOrder: function (order, e) {
        var selectedTagCategory = $(e.currentTarget).closest('.tag-group-category'),
          selectedTagInputs = $(selectedTagCategory).find('input').serializeArray(),
          selectedTagId = _.find(selectedTagInputs, { name: 'tag_group_id' })['value'],
          allTags = $('#main-area').find('.tag-group-category'),
          allTagIds = _.map(allTags, function (tag, index) {
            return _.find($(tag).find('input').serializeArray(), { name: 'tag_group_id' })['value']
          }),
          selectedIdIndex = _.indexOf(allTagIds, selectedTagId)
        if (order > 0) {
          if (selectedIdIndex == 0) {
            allTagIds = allTagIds.splice(1, _.size(allTagIds)).concat([selectedTagId])
          } else {
            var tmpId = allTagIds[selectedIdIndex - 1]
            allTagIds[selectedIdIndex - 1] = allTagIds[selectedIdIndex]
            allTagIds[selectedIdIndex] = tmpId
          }
        } else {
          if (selectedIdIndex == _.size(allTagIds) - 1) {
            allTagIds = [selectedTagId].concat(allTagIds.splice(0, _.size(allTagIds) - 1))
          } else {
            var tmpId = allTagIds[selectedIdIndex + 1]
            allTagIds[selectedIdIndex + 1] = allTagIds[selectedIdIndex]
            allTagIds[selectedIdIndex] = tmpId
          }
        }

        $.ajax({
          url: '/manager/' + helpers.venue_name + '/manage/tags/order/save',
          method: 'POST',
          data: $.param({ 'tag_group_ids[]': allTagIds }),
          success: _.bind(function (response) {
            helpers.refresh()
          }, this),
        })
      },

      showDisabledTooltip: function (e) {
        this.setState({ disableTooltip: true })
      },

      showEditTooltip: function (e) {
        this.setState({ edit_popup: true })
      },

      hideEditTooltip: function (e) {
        this.setState({ edit_popup: false })
      },

      showDeleteTooltip: function (e) {
        this.setState({ deletePopup: true })
      },

      hideDeleteTooltip: function (e) {
        this.setState({ deletePopup: false })
      },

      hideDisabledTooltip: function (e) {
        this.setState({ disableTooltip: false })
      },

      showPriorityUpTooltip: function (e) {
        this.setState({ priority_up_popup: true })
      },

      hidePriorityUpTooltip: function (e) {
        this.setState({ priority_up_popup: false })
      },

      showPriorityDownTooltip: function (e) {
        this.setState({ priority_down_popup: true })
      },

      hidePriorityDownTooltip: function (e) {
        this.setState({ priority_down_popup: false })
      },

      onRemoveHandler: function (e) {
        var selectedTag = $(e.currentTarget).attr('data-tag'),
          filteredList = _.filter(
            this.props.tag_group.tags,
            function (item) {
              return item != selectedTag
            },
            this
          ),
          params = $.param(
            _.reject($(this.getDOMNode()).closest('.tag-group-category').find('input').serializeArray(), { name: 'tags[]' })
          ),
          paramTags = $.param(
            _.map(filteredList, function (tag, index) {
              return { name: 'tags[]', value: tag }
            })
          )
        helpers.saveTags(params + '&' + paramTags)
      },

      showEditDeleteOptions: function () {
        const editButton = (
          <span
            onClick={this.onEditClick}
            onMouseOver={this.showEditTooltip}
            onMouseOut={this.hideEditTooltip}
            data-test="edit-settings-btn"
            className="disable-container"
            style={helpers.buttonStyle}
          >
            <img style={helpers.buttonImgSize} src={helpers.media_url + 'images/icons/settings-button.png'} />
            <span style={helpers.buttonHoverStyle}>
              {this.state.edit_popup ? (
                <span style={{ ...helpers.baseOuterPopupStyle, transform: 'translate(10px,-71px)' }}>
                  <span style={{ display: 'inline-block', textAlign: 'center', height: 15, width: 75 }}>Edit settings</span>
                  <span style={{ ...helpers.baseInnerPopupStyle, top: 25, left: '50%', transform: 'translateX(-50%)' }}></span>
                </span>
              ) : undefined}
            </span>
          </span>
        )
        const deleteButton = (
          <span
            onClick={this.showDeletePopup}
            onMouseOver={this.showDeleteTooltip}
            onMouseOut={this.hideDeleteTooltip}
            style={helpers.buttonStyle}
          >
            <img style={helpers.buttonImgSize} src={helpers.media_url + 'images/icons/trash-button.png'} />
            <span style={helpers.buttonHoverStyle}>
              {this.state.deletePopup ? (
                <span
                  style={{
                    ...helpers.baseOuterPopupStyle,
                    left: '-50%',
                    transform: 'translate(10px,-71px)',
                    width: '100%',
                  }}
                >
                  <span style={{ display: 'inline-block', textAlign: 'center', height: 15, width: 45 }}>Delete</span>
                  <span style={{ ...helpers.baseInnerPopupStyle, top: 25, left: '50%', transform: 'translateX(-50%)' }}></span>
                </span>
              ) : undefined}
            </span>
            {this.state.showDeletePopup ? (
              <div style={{ right: 5 }} className="delete-category local-popup">
                <span className="question">Delete this category?</span>
                {this.state.selectedTagCategory?.tags_in_access_rule?.length ? (
                  <p>
                    At least one Client Tag in this category is tied to an Access Rule. Deleting this category will remove all Client Tags
                    from the associated Access Rule(s).
                  </p>
                ) : undefined}
                <div className="delete-category-confirm-area">
                  {this.state.isLoading ? (
                    <div style={helpers.loadingBackground}>
                      <img
                        alt="sevenrooms-spinner"
                        style={helpers.loadingSpinner}
                        src={`${helpers.media_url}images/SevenRoomsLoader.svg`}
                      />
                    </div>
                  ) : undefined}
                  <div className="link float-right">
                    {!this.state.isLoading ? (
                      <p className="button danger">
                        <a
                          className="save-tag-group-btn"
                          href="javascript:void(0);"
                          data-test="delete-category-btn"
                          onClick={this.deleteCategory}
                        >
                          Delete Category
                        </a>
                      </p>
                    ) : undefined}
                    <p className="button plain">
                      <a href="javascript:void(0);" onClick={this.hideDeletePopup}>
                        Cancel
                      </a>
                    </p>
                    <div className="float-end"></div>
                  </div>
                </div>
                <div className="float-end"></div>
              </div>
            ) : undefined}
          </span>
        )
        const disableButton = (
          <span>
            <span
              onClick={this.showsDisablePopup}
              onMouseOver={this.showDisabledTooltip}
              onMouseOut={this.hideDisabledTooltip}
              className="disable-container"
              data-test="disable-tag-btn"
              style={helpers.buttonStyle}
            >
              <img style={helpers.buttonImgSize} src={helpers.media_url + 'images/icons/disable-button.png'} />
            </span>
            <span style={helpers.buttonHoverStyle}>
              {this.state.disableTooltip ? (
                <span
                  style={{
                    ...helpers.baseOuterPopupStyle,
                    left: '-50%',
                    transform: 'translate(17px, -59px)',
                    width: '100%',
                  }}
                >
                  <span style={{ display: 'inline-block', textAlign: 'center', height: 15, width: 210 }}>
                    By disabling this tag, you will be disabling it for all associated properties.
                  </span>
                  <span style={{ ...helpers.baseInnerPopupStyle, top: 52, left: 138 }}></span>
                </span>
              ) : undefined}
            </span>
            {this.state.showDisablePopup ? (
              <div style={{ right: 5 }} className="delete-category local-popup">
                <span className="question">Disable this category?</span>
                {this.state.selectedTagCategory?.tags_in_access_rule?.length ? (
                  <p>
                    At least one Client Tag in this category is tied to an Access Rule. Deleting this category will remove all Client Tags
                    from the associated Access Rule(s).
                  </p>
                ) : undefined}
                <div className="delete-category-confirm-area">
                  {this.state.isLoading ? (
                    <div style={helpers.loadingBackground}>
                      <img
                        alt="sevenrooms-spinner"
                        style={helpers.loadingSpinner}
                        src={`${helpers.media_url}images/SevenRoomsLoader.svg`}
                      />
                    </div>
                  ) : undefined}
                  <div className="link float-right">
                    {!this.state.isLoading ? (
                      <p className="button danger">
                        <a
                          className="save-tag-group-btn"
                          href="javascript:void(0);"
                          data-test="disable-category-btn"
                          onClick={_.partial(this.disableCategory, this.props.tag_group.disabled)}
                        >
                          Disable Category
                        </a>
                      </p>
                    ) : undefined}
                    <p className="button plain">
                      <a href="javascript:void(0);" onClick={this.hideDisabledPopup}>
                        Cancel
                      </a>
                    </p>
                    <div className="float-end"></div>
                  </div>
                </div>
                <div className="float-end"></div>
              </div>
            ) : undefined}
          </span>
        )
        const enableButton = (
          <span className="float-right">
            <span
              onClick={_.partial(this.disableCategory, this.props.tag_group.disabled)}
              style={{
                border: '1px solid lightgrey',
                margin: '2px 5px 0px 0px',
                color: '#347baf',
                padding: '2px 12px',
                borderRadius: 3,
                width: 130,
                height: 22,
                textAlign: 'center',
                paddingTop: 11,
                fontSize: 14,
                float: 'left',
                cursor: 'pointer',
                display: 'block',
              }}
            >
              Enable
            </span>
          </span>
        )

        const priorityUpButton = (
          <span
            onClick={_.partial(this.changeSortOrder, 1)}
            onMouseOver={this.showPriorityUpTooltip}
            onMouseOut={this.hidePriorityUpTooltip}
            className="priority-up-container"
            data-test="priority-up-btn"
            style={helpers.buttonStyle}
          >
            <img style={helpers.buttonImgSize} src={helpers.media_url + 'images/icons/priority-up-button.png'} />
            <span style={helpers.buttonHoverStyle}>
              {this.state.priority_up_popup ? (
                <span
                  style={{
                    ...helpers.baseOuterPopupStyle,
                    left: '-50%',
                    transform: 'translate(15px,-71px)',
                    height: 15,
                    width: 60,
                  }}
                >
                  <span style={{ display: 'inline-block', textAlign: 'center', height: 15, width: 60 }}>Priority up</span>
                  <span style={{ ...helpers.baseInnerPopupStyle, top: 25, left: '50%', transform: 'translateX(-50%)' }}></span>
                </span>
              ) : undefined}
            </span>
          </span>
        )

        const priorityDownButton = (
          <span
            onClick={_.partial(this.changeSortOrder, -1)}
            onMouseOver={this.showPriorityDownTooltip}
            onMouseOut={this.hidePriorityDownTooltip}
            className="priority-do-container"
            data-test="priority-down-btn"
            style={helpers.buttonStyle}
          >
            <img style={helpers.buttonImgSize} src={helpers.media_url + 'images/icons/priority-down-button.png'} />
            <span style={helpers.buttonHoverStyle}>
              {this.state.priority_down_popup ? (
                <span
                  style={{
                    ...helpers.baseOuterPopupStyle,
                    left: '-100%',
                    transform: 'translate(30px,-71px)',
                    width: 80,
                    height: 15,
                  }}
                >
                  <span style={{ display: 'inline-block', textAlign: 'center', height: 15, width: 75 }}>Priority down</span>
                  <span style={{ ...helpers.baseInnerPopupStyle, top: 25, transform: 'translateX(-12px)' }}></span>
                </span>
              ) : undefined}
            </span>
          </span>
        )

        const showEditButton =
          !this.props.tag_group.disabled &&
          !this.props.tag_group.is_pod_tag &&
          (!this.props.tag_group.is_global || !this.props.disable_global_tag_editing)

        const showDeleteButton =
          !this.props.tag_group.disabled &&
          !this.props.tag_group.is_pod_tag &&
          !this.props.tag_group.is_default &&
          (!this.props.tag_group.is_global || !this.props.disable_global_tag_editing)

        const showEnableButton =
          this.props.tag_group.disabled && (!this.props.tag_group.is_global || !this.props.disable_global_tag_editing)

        const showDisableButton = !showDeleteButton && (!this.props.tag_group.is_global || !this.props.disable_global_tag_editing)

        if (showEnableButton) {
          return { enableButton }
        } else {
          return (
            <span className="float-right">
              {showEditButton ? editButton : undefined}
              {showDeleteButton ? deleteButton : undefined}
              {showDisableButton ? disableButton : undefined}
              {priorityUpButton} {priorityDownButton}
            </span>
          )
        }
      },

      render: function () {
        if (this.state.mode == 'edit') {
          return <helpers.editTagCategory onClickNevermind={this.onClickNevermind} tag_group={this.props.tag_group} />
        } else {
          var editOptions = this.showEditDeleteOptions()
          var tag_group = this.props.tag_group,
            optional_tags = this.props.optional_tags,
            newTag =
              this.state.mode == 'add' ? (
                <helpers.newTag
                  onClickNevermind={this.onClickNevermind}
                  ref="new_tag"
                  existing_list={tag_group.tags}
                  is_editable={tag_group.is_editable}
                  is_default={tag_group.is_default}
                  allowed_list={optional_tags[tag_group.type]}
                  tag_group={tag_group}
                  tag_groups={this.props.tag_groups}
                  setTagDuplicateWarningModalIsActive={this.props.setTagDuplicateWarningModalIsActive}
                  setTagDuplicateWarningModalData={this.props.setTagDuplicateWarningModalData}
                />
              ) : undefined,
            subHeader = _.filter([
              tag_group.is_pod_tag ? 'Pod' : tag_group.is_global ? 'Global' : 'Local',
              tag_group.privacy == 'PRIVATE' ? 'Visible to Superuser only' : undefined,
              tag_group.is_restricted ? 'Restricted tag category' : undefined,
              tag_group.show_reservation ? 'Show on reservation' : undefined,
              tag_group.show_chit ? 'Show on chit' : undefined,
            ]).join(', '),
            tagHeader = (
              <div>
                <div style={{ fontSize: 16, padding: '2px 0px', marginLeft: 20, fontWeight: 'bold' }}>
                  <span style={{ color: '#AAA' }}>
                    <span style={{ float: 'left' }}>
                      {tag_group.name_display || tag_group.name}
                      {tag_group.vip ? ' (VIP)' : undefined}
                      {tag_group.is_autotag ? ' - Auto-tag' : tag_group.is_default ? ' - default' : undefined}
                      {tag_group.disabled ? ' (disabled)' : undefined}
                    </span>
                    {tag_group.is_restricted ? (
                      <span
                        style={{
                          float: 'left',
                          backgroundSize: 15,
                          height: 20,
                          width: 20,
                          'background-position-x': 5,
                          backgroundRepeat: 'no-repeat',
                          backgroundImage: 'url(' + helpers.media_url + 'images/icons/lock-icon.png)',
                        }}
                      ></span>
                    ) : undefined}
                  </span>
                </div>
                <div
                  style={{
                    width: '100%',
                    color: '#AAA',
                    fontSize: 12,
                    marginLeft: 20,
                    clear: 'both',
                    fontStyle: 'italic',
                  }}
                >
                  {subHeader}
                </div>
              </div>
            ),
            tags = tag_group.tags.map(function (tag_name, index) {
              return (
                <helpers.viewTag
                  key={index}
                  onRemoveHandler={this.onRemoveHandler}
                  name={tag_name}
                  name_display={tag_group.tag_name_displays[tag_name] || tag_name}
                  is_editable={
                    !tag_group.is_pod_tag &&
                    !tag_group.is_autotag &&
                    (!tag_group.is_global || !this.props.disable_global_tag_editing) &&
                    (!optional_tags[tag_group.type] || !optional_tags[tag_group.type].includes(tag_name))
                  }
                  is_deletable={
                    tag_name != 'Referrer' &&
                    tag_group.is_deletable &&
                    !tag_group.is_autotag &&
                    !tag_group.is_pod_tag &&
                    (!tag_group.is_global || !this.props.disable_global_tag_editing)
                  }
                  is_autotag={tag_group.is_autotag}
                  color={tag_group.color_hex}
                  tag_group_id={tag_group.id}
                  isPodTagCategory={tag_group.is_pod_tag}
                />
              )
            }, this),
            deletedTags = tag_group.deleted_tags.map(function (tag_name, index) {
              return (
                <helpers.viewTag
                  key={index}
                  name={tag_name}
                  name_display={tag_group.deleted_tag_name_displays[tag_name] || tag_name}
                  is_editable={false}
                  is_deletable={false}
                  is_autotag={false}
                  is_restorable={true}
                  color={tag_group.color_hex}
                  tag_group_id={tag_group.id}
                  isPodTagCategory={tag_group.is_pod_tag}
                />
              )
            }, this),
            addTagOption =
              this.state.mode === 'add' ||
              tag_group.is_autotag ||
              tag_group.is_pod_tag ||
              tag_group.name_display === 'Referral' ? undefined : (
                <span>
                  <div
                    className="tag"
                    style={{ border: 0, padding: 0, cursor: 'pointer' }}
                    onClick={this.addNewTagHandler}
                    data-test="add-tag-btn"
                  >
                    <span
                      style={{
                        float: 'left',
                        cursor: 'pointer',
                        padding: '0px 5px',
                        height: '100%',
                        fontWeight: 'normal',
                        display: 'block',
                        lineHeight: '25px',
                        color: '#555',
                        border: '1px solid #CCCCCC',
                        borderRadius: 3,
                        background: '#dedede',
                        fontSize: 15,
                      }}
                    >
                      +
                    </span>
                  </div>
                  {!_.size(tag_group.tags) ? <div style={{ color: '#AAA' }}>{'(Click to add new tags)'}</div> : undefined}
                </span>
              )
          var hiddenFollowers = _.map(tag_group.followers, function (x, id) {
            return <input name="fid" type="hidden" value={x.id} key={id} />
          })
          var hidden_tags = tag_group.tags.map(function (tag, index) {
            return <input key={index} type="hidden" name="tags[]" value={tag} />
          })

          return (
            <div
              className="tag-group-category"
              id={tag_group.id}
              onMouseEnter={this.onMouseOverHandler}
              onMouseLeave={this.onMouseOutHandler}
              style={_.extend(
                {},
                {
                  borderBottom: '1px solid #EEE',
                  paddingTop: 10,
                  color: this.props.tag_group.disabled ? '#AAA' : undefined,
                  backgroundColor: this.state.active ? 'rgba(250,250, 250, 1.0)' : 'rgba(255,255, 255, 0.5)',
                }
              )}
            >
              <input type="hidden" name="tag_group_id" value={tag_group.id} />
              <input type="hidden" name="name" value={tag_group.name} />
              <input type="hidden" name="name_display" value={tag_group.name_display} />
              <input type="hidden" name="color" value={tag_group.color_hex} />
              <input type="hidden" name="privacy" value={tag_group.privacy} />
              <input type="hidden" name="scope" value={Number(tag_group.is_global)} />
              <input type="hidden" name="is_restricted" value={tag_group.is_restricted} />
              <input type="hidden" name="show_chit" value={tag_group.show_chit} />
              <input type="hidden" name="show_reservation" value={tag_group.show_reservation} />
              <input type="hidden" name="show_custom_tags_on_widget" value={tag_group.show_custom_tags_on_widget} />
              <input type="hidden" name="vip" value={tag_group.vip} />
              <input type="hidden" name="is_global" value={tag_group.is_global} />
              {hiddenFollowers}
              {hidden_tags}
              <input type="hidden" name="default" value={tag_group.is_default} />
              <span className="float-left" style={{ height: '39px' }}>
                {tagHeader}
              </span>
              {this.state.active && this.state.mode != 'add' ? editOptions : undefined}
              <div style={{ padding: '10px 20px', clear: 'both' }}>
                {!tag_group.disabled ? (
                  <span>
                    <div>
                      {tags}
                      {!tag_group.is_global || !this.props.disable_global_tag_editing ? addTagOption : undefined}
                    </div>
                    <div>{newTag}</div>
                  </span>
                ) : undefined}
              </div>
              {!tag_group.disabled && helpers.is_superhero && tag_group.deleted_tags.length > 0 && (
                <div>
                  <div style={{ color: '#AAA', marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontSize: 12, fontStyle: 'italic' }}>
                      Deleted tags (visible to superheros only) - click the x to restore a tag
                    </span>
                  </div>
                  <div style={{ padding: '10px 20px', clear: 'both' }}>{deletedTags}</div>
                </div>
              )}
            </div>
          )
        }
      },
    }),

    normalizeTagValue: function (tag_id, tag_name, tag_value) {
      var args = Array.prototype.slice.call(arguments)
      return args.join('##')
    },

    tagGroupCanShowCustom: tagGroup => ['Dietary restrictions', 'Special Occasions'].includes(tagGroup.name) && tagGroup.is_default,

    migrationView: React.createClass({
      getInitialState: function () {
        return {
          save_popup: false,
          old_tag: null,
          new_tag: null,
        }
      },

      onSubmitHandler: function () {
        var old_tag_option = $(this.getDOMNode()).find('[name=old_tag]'),
          new_tag_option = $(this.getDOMNode()).find('[name=new_tag]'),
          old_tag = $(old_tag_option).val(),
          new_tag = $(new_tag_option).val(),
          old_tag_text = $(old_tag_option).find(':selected').text(),
          new_tag_text = $(new_tag_option).find(':selected').text()

        this.setState({
          save_popup: true,
          old_tag: old_tag,
          new_tag: new_tag,
          old_tag_text: old_tag_text,
          new_tag_text: new_tag_text,
        })
      },

      onSubmitCancel: function () {
        this.setState({ save_popup: false })
      },

      onSaveHandler: function () {
        $.post(
          '/manager/' + helpers.venue_name + '/manage/tags/migrate',
          {
            domain: helpers.domain,
            old_tag: this.state.old_tag,
            new_tag: this.state.new_tag,
          },
          function (response) {
            alert("Migration kicked off. Please don't submit again")
          }
        )
        this.setState({ save_popup: false })
      },

      render: function () {
        var selectedOptions = this.props.tag_groups.map(function (tag_group, key) {
          var tagGroupNameDisplay = tag_group.name_display || tag_group.name
          const tagNameDisplays = tag_group.tag_name_displays ?? {}
          return (
            <span>
              <option key={tag_group.id} disabled="disabled">
                {tagGroupNameDisplay}
              </option>
              {tag_group.tags.map(function (item, key) {
                return (
                  <option
                    key={tag_group.id + '##' + key}
                    data-tag_group_name={tag_group.name}
                    data-tag_group_id={tag_group.id}
                    data-privacy={tag_group.privacy}
                    data-value={item}
                    value={helpers.normalizeTagValue(tag_group.privacy, tag_group.id, tag_group.name, item)}
                  >
                    {tagGroupNameDisplay + ' > ' + (tagNameDisplays[item] ?? item)}
                  </option>
                )
              }, this)}
            </span>
          )
        }, this)

        return (
          <div>
            {this.state.save_popup ? (
              <div className="save-migration local-popup">
                <span className="question">
                  Are you sure you want to migrate this tag? This will migrate all
                  <b> {this.state.old_tag_text} </b> to <b> {this.state.new_tag_text} </b>
                  Please give it a few minutes to finish the migration.
                </span>
                <div className="save-category-confirm-area">
                  <div className="link float-right">
                    <p className="button">
                      <a className="save-tag-group-btn" href="javascript:void(0);" onClick={this.onSaveHandler}>
                        Confirm
                      </a>
                    </p>
                    <p className="button plain">
                      <a href="javascript:void(0);" onClick={this.onSubmitCancel}>
                        nevermind
                      </a>
                    </p>
                    <div className="float-end"></div>
                  </div>
                </div>
                <div className="float-end"></div>
              </div>
            ) : null}
            <table style={{ width: 700 }}>
              <tr>
                <td colSpan="2" style={{ padding: '10px 20px', border: 0, fontSize: 15 }}>
                  Tag migration
                </td>
              </tr>
              <tr>
                <td style={{ padding: '10px 20px', border: 0, fontSize: 15 }}>Old tag</td>
                <td style={{ padding: '10px 20px', border: 0, fontSize: 15 }}>New tag</td>
              </tr>
              <tr>
                <td onClick={this.onClickHandler} style={{ padding: '10px 20px', border: 0 }}>
                  <select name="old_tag" onChange={this.onSelectHandler}>
                    <option key="______">N/A</option>
                    {_.flatten(selectedOptions)}
                  </select>
                </td>
                <td onClick={this.onClickHandler} style={{ padding: '10px 20px', border: 0 }}>
                  <select name="new_tag" onChange={this.onSelectHandler}>
                    <option key="______">N/A</option>
                    {_.flatten(selectedOptions)}
                  </select>
                </td>
              </tr>
              <tr>
                <td colSpan="2" style={{ padding: '10px 20px', border: 0 }}>
                  <input type="submit" onClick={this.onSubmitHandler} />
                </td>
              </tr>
            </table>
          </div>
        )
      },
    }),

    tagsCompositeView: React.createClass({
      render: function () {
        var tagGroupsView = (
          <helpers.tagGroupsView
            key={new Date().getTime()}
            disable_global_tag_editing={this.props.disable_global_tag_editing}
            tag_groups={this.props.tag_groups}
            optional_tags={this.props.optional_tags}
            is_consumer_tags_enabled={this.props.is_consumer_tags_enabled}
          />
        )

        var tagGroupsMigrationView = <helpers.migrationView tag_groups={this.props.tag_groups} />

        return (
          <div>
            {tagGroupsView}
            {helpers.is_superhero ? tagGroupsMigrationView : null}
          </div>
        )
      },
    }),

    tagGroupsView: React.createClass({
      getInitialState: function () {
        return {
          new_tag: false,
          showDuplicateWarning: false,
          tagDuplicateWarningModalData: {},
        }
      },

      onClickHandler: function () {
        this.setState({ new_tag: true })
      },

      onClickNevermind: function () {
        this.setState({ new_tag: false })
      },

      onTagDuplicateWarningModalSubmit: function (tagCategory) {
        const params = $(document.getElementById(tagCategory.id)).find('input').serializeObject()
        params['tags[]'] = this.state.tagDuplicateWarningModalData.tags
        helpers.saveTags($.param(params))
      },

      setTagDuplicateWarningModalData: function (data) {
        this.setState({ tagDuplicateWarningModalData: data })
      },

      setTagDuplicateWarningModalIsActive: function (status) {
        this.setState({ showDuplicateWarning: status })
      },

      getActualTags: function ({ originalTagCategory, tags }) {
        if (typeof tags === 'string' || tags instanceof String) {
          return [tags.trim()]
        }
        return tags.map(tag => tag.trim()).filter(tag => !originalTagCategory.tags.includes(tag.trim()) && tag) ?? [] // Exclude existing tags or ''
      },

      getActualCategories: function ({ tagCategory, tagCategories }) {
        return tagCategories.filter(category => category.id !== tagCategory.id) // Exclude same category
      },

      render: function () {
        var booked_by_users = helpers.booked_by_users,
          tagGroups = this.props.tag_groups.map(function (tag_group, index) {
            if (tag_group.name_display == 'SevenRooms App' && !this.props.is_consumer_tags_enabled) {
              return null
            }
            return (
              <helpers.viewTagCategory
                key={index}
                tag_group={tag_group}
                tag_groups={this.props.tag_groups}
                is_new={true}
                disable_global_tag_editing={this.props.disable_global_tag_editing}
                optional_tags={this.props.optional_tags}
                booked_by_user={booked_by_users}
                setTagDuplicateWarningModalData={this.setTagDuplicateWarningModalData}
                setTagDuplicateWarningModalIsActive={this.setTagDuplicateWarningModalIsActive}
              />
            )
          }, this)
        return (
          <div style={{ marginBottom: 20 }}>
            <helpers.tagDuplicateWarningModal
              isActive={this.state.showDuplicateWarning}
              setIsActive={this.setTagDuplicateWarningModalIsActive}
              onSubmit={this.onTagDuplicateWarningModalSubmit}
              tagData={this.state.tagDuplicateWarningModalData}
              tagCategories={this.props.tag_groups}
            />
            <div className="header" style={{ borderBottom: '1px solid #e1e1e1', height: 40, padding: 10, overflow: 'hidden' }}>
              <span className="float-left" style={{ fontSize: 20, fontFamily: 'Oswald' }}>
                {helpers.identifier == 'tags' ? 'CLIENT TAGS' : 'RESERVATION TAGS'}
              </span>
              <span className="float-right">
                <p className="button" style={{ float: 'left' }}>
                  <a onClick={this.onClickHandler} data-test="add-category" href="javascript:void(0)">
                    Add category
                  </a>
                </p>
              </span>
            </div>
            <span>
              {this.state.new_tag ? (
                <helpers.editTagCategory
                  disable_global_tag_editing={this.props.disable_global_tag_editing}
                  onClickNevermind={this.onClickNevermind}
                  new_tag={true}
                  tag_group={{ show_chit: 1, vip: 0, show_reservation: 1, is_restricted: 0, tags: [] }}
                />
              ) : undefined}
              {tagGroups}
            </span>
          </div>
        )
      },
    }),

    editTagCategory: React.createClass({
      onSaveHandler: function () {
        window.metric.track('AutoTagging.ChangeTagDisplay')
        var dataParams = $(this.getDOMNode()).find('input').serialize(),
          color = $(this.getDOMNode()).find('[name=color]').val(),
          tagGroupIds = _.map($('#main-area').find('[name=tag_group_id]'), function (x) {
            return $(x).val()
          })
        helpers.saveTags(dataParams + '&color=' + color + '&tag_group_ids[]=' + tagGroupIds)
      },

      onClickHandler: function (e) {
        $('#colorpicker').hide()
      },

      render: function () {
        var hidden_tags = (this.props.tag_group || {}).tags.map(function (tag, index) {
            return <input key={index} type="hidden" name="tags[]" value={tag} />
          }),
          followers = this.props.tag_group.followers || {},
          tag_group = this.props.tag_group
        return (
          <div
            className="new_tag"
            onClick={this.onClickHandler}
            style={_.extend({}, helpers.fontStyle, { padding: '15px 25px', overflow: 'hidden', borderBottom: '1px solid #EEE' })}
          >
            <input type="hidden" name="tag_group_id" value={(tag_group || {}).id} />
            {hidden_tags}
            <div style={{ clear: 'both' }}>
              <helpers.tagGroupNameField
                new_tag={this.props.new_tag}
                name={(tag_group || {}).name}
                name_display={(tag_group || {}).name_display}
                is_disabled={(tag_group || {}).is_default || (tag_group || {}).is_pod_tag}
              />
              <helpers.colorSelection color={(tag_group || {}).color_hex} />
            </div>
            <div style={{ clear: 'both' }}>
              {helpers.domain == 'ReservationActual' && (tag_group || {}).is_default ? undefined : (
                <p className="label" style={_.extend({}, { paddingTop: 20 }, helpers.labelCss)}>
                  Classification
                </p>
              )}
              <helpers.classificationSelection
                tag_group_id={(tag_group || {}).id}
                is_global={(tag_group || {}).is_global}
                disable_global_tag_editing={this.props.disable_global_tag_editing}
              />
              {helpers.domain == 'VenueGroupClient' ? <helpers.vipSelection vip={(tag_group || {}).vip} /> : undefined}
              <p className="label" style={_.extend({}, helpers.labelCss, { paddingTop: 20, margin: 0 })}>
                Display
              </p>
              <helpers.superuserSelection privacy={(tag_group || {}).privacy} />
              {helpers.domain == 'VenueGroupClient' ? (
                <helpers.restrictedSelection
                  is_restricted={(tag_group || {}).is_restricted}
                  is_disabled={tag_group && tag_group.is_autotag}
                />
              ) : undefined}
              <helpers.showchitSelection show_chit={(tag_group || {}).show_chit} />
              <helpers.showReservationSelection show_reservation={(tag_group || {}).show_reservation} />
              {helpers.tagGroupCanShowCustom(tag_group) && (
                <helpers.showCustomTagsSelection show_custom_tags_on_widget={(tag_group || {}).show_custom_tags_on_widget} />
              )}
              <helpers.followersSelection followers={followers} />
              <div style={{ clear: 'both', padding: '15px 0px' }}>
                <p className="button" style={{ float: 'left' }}>
                  <a onClick={this.onSaveHandler} data-test="save-category-btn" href="javascript:void(0)" className="save-tag-group-btn">
                    Save
                  </a>
                </p>
                <a
                  style={{ float: 'left', lineHeight: '40px', paddingLeft: 20 }}
                  href="javascript:void(0)"
                  onClick={this.props.onClickNevermind}
                  data-test="cancel-category-btn"
                  className="cancel-tag-group-btn"
                >
                  Nevermind
                </a>
              </div>
            </div>
          </div>
        )
      },
    }),

    setTagDuplicateWarningModalIsActive: function (status) {
      this.setState({ showDuplicateWarning: status })
    },

    tagDuplicateWarningModal: React.createClass({
      getInitialState: function () {
        return {}
      },

      onSubmitHandler: function () {
        this.props.setIsActive(false)
        this.props.onSubmit(this.props.tagData.tagCategory)
      },

      onCancel: function () {
        this.props.setIsActive(false)
      },

      render: function () {
        if (!this.props.isActive) {
          return <div></div>
        }

        const tags = this._owner.getActualTags({ originalTagCategory: this.props.tagData.tagCategory, tags: this.props.tagData.tags })
        const categories = this._owner.getActualCategories({
          tagCategory: this.props.tagData.tagCategory,
          tagCategories: this.props.tagCategories,
        })
        const items = tags
          .map(tag => ({
            tag: tag.trim(),
            categories: categories
              .filter(category => {
                const tagCategoryTags = category.tags.map(t => category.tag_name_displays[t] || t)
                return tagCategoryTags.includes(tag.trim())
              })
              .map(category => `"${category.name_display || category.name}"`),
          }))
          .filter(tag => tag.categories.length)

        const inlineItems = items.map(({ tag, categories }) => (
          <span>
            <span color="primaryFont" className="jwzjlF">
              {`"${tag.trim()}" in the Tag ${categories.length === 1 ? 'Category ' : 'Categories'} ${categories.join(', ')}`}
            </span>
            <br />
          </span>
        ))

        return (
          <div className="kVvXRE">
            <div className="hNKmEh">
              <div role="dialog" aria-label="TagDuplicateWarningModal" className="iYejQO">
                <div className="iucIfS">
                  <div className="buRkGq">
                    <div className="fatFmq">
                      <div className="kIJtZe abNmM ffWywg">
                        <div className="kIJtZe bTYyKp">
                          <i className="hmlSXr VMSWeb VMSWeb-warning" color="warning" aria-hidden="true"></i>
                          <div className="gPLjmC">
                            <span color="warning" className="jdLJEo">
                              Warning
                            </span>
                          </div>
                        </div>
                        <div className="kIJtZe cevBaN ffWywg">
                          <div>
                            <h2 color="primaryFont" className="ncvJi kxDNiH">
                              Duplicate Tag in another Tag Category
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="kIJtZe iIolVR">
                        <button aria-label="close" className="qsJiS lldGrs" type="button" onClick={this.onCancel}>
                          <i className="gDKxMF VMSWeb VMSWeb-close VMSWeb-lg" color="primaryIcons" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                    <div className="iqBRls">
                      {inlineItems}
                      <br />
                      <span color="primaryFont" className="jwzjlF">
                        Do you still want to create this Tag?
                      </span>
                    </div>
                    <div className="jbuENL dkXmUH">
                      <div className="kIJtZe hgnvkT">
                        <div className="kIJtZe kyAsjg">
                          <button className="qsJiS ksMdOU" type="button" onClick={this.onCancel}>
                            Cancel
                          </button>
                          <button className="qsJiS iFhvKr" type="button" onClick={this.onSubmitHandler}>
                            Create Anyway
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      },
    }),

    init: function (identifier, div_id, is_superhero, media_url, venue_name, venue_group_id, is_consumer_tags_enabled) {
      helpers.identifier = identifier
      helpers.media_url = media_url
      helpers.venue_name = venue_name
      helpers.is_superhero = is_superhero
      helpers.venue_group_id = venue_group_id
      helpers.domain = identifier == 'tags' ? 'VenueGroupClient' : 'ReservationActual'
      helpers.id = div_id
      helpers.is_consumer_tags_enabled = is_consumer_tags_enabled == 'True' ? true : false
      helpers.fetch_url = '/manager/' + helpers.venue_name + '/data/' + (identifier == 'tags' ? 'allclienttags' : 'allreservationtags')
      helpers.initLoad()
    },

    initLoad: function () {
      $.get('/manager/' + helpers.venue_name + '/data/bookedbynames', function (response) {
        helpers.booked_by_users = _.filter(response.payload.content.booked_by_users, function (u) {
          return u.is_user
        })
        helpers.refresh()
      }).fail(function () {
        Interface._alert('Something went wrong, please try later!')
      })
    },

    refresh: function () {
      $.get(helpers.fetch_url, function (response) {
        React.render(
          <helpers.tagsCompositeView
            key={new Date().getTime()}
            disable_global_tag_editing={response.payload.content.disable_global_tag_editing}
            tag_groups={response.payload.content.tag_groups}
            optional_tags={response.payload.content.optional_tags}
            is_consumer_tags_enabled={helpers.is_consumer_tags_enabled}
          />,
          document.getElementById(helpers.id)
        )
      }).fail(function () {
        Interface._alert('Something went wrong, please try later!')
      })
    },
  }

  return {
    init: helpers.init,
  }
})()
